<template>
    <article>
    
    <h3>제1조 목적</h3>
    
    
    <p>이 약관은 쓰리랩스 주식회사(“회사”)의 「마켓뱅(Marketbang) 이용약관」에서 정하는 바에 따라 판매자의 가입절차, 권리의무 등에 관한 사항을 규정함을 목적으로 합니다.</p>
    
    <h3>제2조 용어의 정의</h3>
    
    
    <p>이 약관에서 사용하는 용어의 정의는 달리 정하지 않는 한 「마켓뱅(Marketbang) 이용약관」 제2조에서 정하는 바에 따릅니다.</p>
    
    <h3>제3조 적용대상</h3>
    
    
    <p>이 약관은 회사의 서비스에 판매자 회원으로 가입한 자(가입희망자 포함)에게 적용되고, 판매자회원으로 가입하려는 자가 가입신청 절차에서 이 약관에 동의함으로써 그 효력이 발생합니다.</p>
    
    <h3>제4조 판매자 가입 (지원)</h3>
    
    <ol>
    <li>①	판매자 회원으로 가입하고자 하는 자는 회사의 인터넷사이트에 접속하여 회사가 요청하는 이메일주소, 전화번호, 사업자정보 등 정보를 등록하여 가입신청을 합니다. 이 때 회사가 요구하는 정보를 제공하여야 하고, 이를 제공하지 않거나 불충분, 불성실하게 제공하는 경우 제2항의 승낙이 이루어지지 않을 수 있습니다.</li>
    <li>②	회사가 가입신청 및 신청자의 자격요건을 확인하여 가입신청을 승낙함으로써 판매자 회원 가입이 이루어집니다. 위 확인 및 승낙 절차는 회사의 사정에 따라 수 일이 소요될 수도 있습니다.</li>
    <li>③	회사는 신청자가 제공한 정보를 검토하여, 회사의 정책에 비추어 합리적인 이유가 있는 경우 가입신청을 거절할 수 있습니다. 다만 한 번의 거절이 영구적인 거절을 의미하는 것은 아니며, 해당 신청자는 추후 회사에 다시 판매자 회원 가입신청을 할 수 있습니다.</li>
    <li>④	회사는 판매자의 기본정보를 원활한 거래를 위해 구매자에게 제공할 수 있으며 세금계산서의 발행등 기능제공을 위해 유관서비스에 기본적인 정보가 등재되고 필요시 계정등이 생성될 수 있습니다.</li>
    </ol>
    
    <h3>제5조 판매자 활동</h3>
    <ol>
    <li>①	판매자는 회사가 제공하는 인터넷사이트내 판매를 희망하는 상품과 상품과 관련된 정보,상품가격등을 제공하여 상품을 구매자에게 판매할 수 있습니다.</li>
    <li>②	판매자가 등록한 상품 및 판매자 관련 정보에 대한 수정 및 관리 책임은 판매자에게 있습니다.</li>
    <li>③	판매자는 회사가 제시하는 정책을 준수하며 판매활동을 하여야 하며, 이를 이행하지 않는 경우 회사는 즉시 판매자의 활동을 정지 또는 탈퇴처리 할 수 있습니다. 판매와 관련한 회사의 중요한 정책은 판매자가 사용하는 마켓뱅 파트너 관리자 사이트에 고지되며 이는 공지사항, 게시판, 설명문구등 다양한 형태로 안내 됩니다.</li>
    <li>④	판매와 관련된 회사의 주요 정책의 변경이 있는 경우 회사는 변경 내용의 그 적용일자로부터 7일 이전(단, 판매자에게 불리한 개정일 경우 30일 이전)에 변경사항을 마켓뱅 파트너 관리자 사이트 게재, 이메일, 유선연락 중의 수단으로 고지하며, 판매자가 변경된 정책에 동의하지 않는 경우 회원탈퇴(이용계약의 해지)를 요청할 수 있으며, 변경 적용일까지 거부의사를 표현하지 않는 경우 변경내용에 동의한 것으로 간주 합니다.</li>
    <li>⑤	회사는 서비스의 일부 또는 전부를 유료화 할 수 있습니다.</li>
    <li>⑥	회사와 판매자는 별도의 상품 홍보관련한 계약을 체결할 수 있습니다.</li>
    <li>⑦	판매자가 마켓뱅 서비스를 통해 연결된 주문, 거래처를 우회하여 거래를 처리하거나 마켓뱅 서비스를 통해 획득한 주문자의 정보를 회사가 의도하지 않은 방법으로 이용하는 경우 회사는 판매자의 활동을 정지 또는 탈퇴처리 할 수 있습니다.</li>
    <li>⑧	판매자는 마켓뱅 서비스를 통해 판매하는 상품에 대한 모든 책임은 판매자 자신에게 있음을 인지하고 관련법령 (소비자보호에 관한 법률, 전자금융거래법, 식품위생법, 부가가치세법, 정보통신망이용촉진 및 정보보호등에 관한 법률, 저작권법등 – 이에 국한되지 않음)을 준수하여야 합니다.</li>
    <li>⑨	회사는 상품을 구매하는 구매자에 대해 어떠한 보증이나 대리를 하지 않습니다. 판매자는 구매자의 정보등을 정확하게 확인하여 거래를 진행하여야 하며 거래에 대한 모든 책임은 당사자인 판매자와 구매자에게 있습니다.</li>
    </ol>
    
    <h3>제6조 서비스 이용료</h3>
    <ol>
    <li>①	판매자는 마켓뱅 서비스 이용료로 판매된 상품 거래액의 5%를 회사에 지급합니다. (거래액은 공급가를 기준으로 하며, 세금계산서 발행 시 부가가치세가 부가됩니다.)</li>
    <li>②	회사는 구매자가 전자결제대행서비스를 통해 결제한 거래대금에서 서비스 이용료를 차감 후 판매자에게 지급할 수 있습니다. 지급주기는 판매자와 회사간의 별도 합의가 있지 않는 한 월 1회로 합니다. 회사는 판매자에게 정산 시기에 대해 사전 고지 합니다.</li>
    </ol>
    
    <h3>제7조 판매자의 의무</h3>
    
    <ol>
    <li>①	판매자는 관련법령에 의거 상품의 판매에 필요한 모든 자격을 보유하여야 하며, 이를 갖추지 않은 상태로 상품의 판매로 인해 발생하는 모든 민형사상의 책임은 판매자에게 있습니다.</li>
    <li>②	판매자는 판매자가 판매하는 상품에 대한 적법한 라이선스, 유통자격 등을 정당하게 보유하여야 하며, 등록하는 상품에 대한 적법성, 정확성에 대한 책임을 집니다.</li>
    <li>③	판매자는 판매한 상품의 교환 및 환불 요청에 대해 관련법령 및 판매자의 정책에 의거 직접 이를 처리 합니다.</li>
    <li>④	판매자는 마켓뱅이 제공하는 구매자의 개인정보(사업자정보, 주소지,이름,  휴대폰번호등)를 마켓뱅 내 주문의 처리 및 배송을 위한 용도로만 사용하여야 합니다. 구매자의 개인정보를 영업활동에 사용, 제3자에게 제공하는 등의 행위는 모두 마켓뱅과 구매자가 동의한 개인정보취급방침에 위배됨을 인지하고 이로 인해 발생할 수 있는 모든 유무형의 책임을 집니다. 이는 서비스 계약종료, 회원탈퇴 및 이용 자격정지 조치 이후에도 적용 됩니다.</li>
    <li>⑤	회사는 판매자가 지속적으로 구매자의 주문요청을 거부하거나 배송까지 상당시간이 소요되어 주문 취소율이 일정 수준을 초과하는 경우, 서비스의 품질향상을 위해 판매자에게 경고, 패널티, 이용정지 등의 필요한 조치를 취할 수 있습니다.</li>
    <li>⑥	판매자는 위 5항의 조치에 대해 이의가 있는 경우 이를 신청할 수 있으며 정당한 사유가 있는 경우 회사는 5항의 조치를 해제할 수 있습니다.</li>
    </ol>
    
    <h3>제8조 게시물의 저작권 및 배타적 이용허락</h3>
    <ol>
    <li>①	판매자는 그가 게시하는 정보 및 콘텐츠가 내용과 형식에 있어 충실하고 적절하도록 관리할 의무를 부담합니다. 특히 「마켓뱅(Marketbang) 이용약관」 제16조에서 정하는 내용을 확인하고 각별히 유의하여야 합니다. 회사는 판매자가 게시한 정보에 내용상, 형식상의 문제가 있다고 판단하는 경우 수정조치를 요구할 수 있습니다.</li>
    <li>②	회사는 판매자의 상품 또는 판매자가 작성한 정보를 활용하여 판촉, 홍보등에 활용할 수 있습니다. 이에 관한 자세한 사항은 「마켓뱅(Marketbang) 이용약관」 제16조 2항에 따릅니다.</li>
    </ol>
    
    <h3>제9조 회원탈퇴 및 자격정지 조치</h3>
    
    <ol>
    <li>①	판매자가 회원탈퇴(이용계약의 종료)를 희망하는 경우 회원탈퇴 의사를 회사에 전달한 날로부터 3개월 이후 탈퇴가 완료됩니다. 탈퇴처리가 완료될 때까지 판매자는 「마켓뱅(Marketbang) 이용약관」, 「마켓뱅(Marketbang) 판매자 약관」에 의거하여 판매자로서의 활동과 의무를 다하여야 합니다.</li>
    <li>②	판매자가 회원가입 이후 다음 각 호의 사유에 해당하게 된 경우, 회사는 즉시 해당 회원에 대한 탈퇴조치 또는 자격정지 조치를 할 수 있습니다.
        <ol>
            <li>「마켓뱅(Marketbang) 이용약관」 또는 이 약관을 위반하고, 회사의 시정요구에도 불구하고 이를 적시에 시정하지 않는 경우</li>
            <li>판매자가 영업정지, 금융 기관의 거래정지, 회생 및 파산절차의 개시등의 행정처분등으로 정상적인 사업활동이 불가능 하다고 판단될 때</li>
            <li>가입신청 시 회사에 제공한 정보가 사실과 다른 경우</li>
            <li>주류 상품을 판매하기 위한 적법한 자격을 갖추지 못한 경우</li>
            <li>불성실한 활동으로 회사의 이미지, 영업활동에 부정적 영향을 미치거나 유·무형적 손해를 끼친 경우</li>
            <li>판매자가 마켓뱅 서비스를 통해 연결된 주문, 거래처를 마켓뱅 서비스를 통하지 않고 우회하여 거래를 처리하거나 마켓뱅 서비스를 통해 획득한 주문자의 정보를 회사가 의도하지 않은 방법으로 이용하는 경우</li>
            <li>기타 회사의 운영정책상 불가피하다고 판단되는 경우</li>
        </ol>
    </li>
    <li>③	판매자는 회원탈퇴 및 자격정지 전 완료하지 못한 거래가 있는 경우 배송,교환,환불등 필요한 조치를 해야하며 판매자의 귀책사유로 발생한 회원탈퇴 또는 자격정지로 인한 피해에 대해 회사는 책임이 없습니다.</li>
    </ol>
    
    <h3>제 10조 이용약관과의 관계</h3>
    <ol>
    <li>①	이 약관은 「마켓뱅(Marketbang) 이용약관」의 개별약관에 해당합니다.</li>
    <li>②	이 약관과 「마켓뱅(Marketbang) 이용약관」의 내용이 상충하는 경우 이 약관이 우선 적용되며, 이 약관에서 정하지 않은 내용에 관해서는 마켓뱅(Marketbang) 이용약관」이 적용됩니다.</li>
    <li>③	이 약관에 의해 가입하는 판매자는「마켓뱅(Marketbang) 이용약관」의 내용을 충분히 숙지하고 내용에 동의하는 것임을 확인합니다.</li>
    <li>④	이 약관은 대한민국 법률을 준거법으로 하며, 이 약관과 관련하여 당사자 간에 분쟁이 발생하는 경우 서울중앙지방법원을 제1심 전속관할로 합니다.</li>
    </ol>
    
    시행일자 : 2024년 7월 30일
    
    
    
    
    </article>
    </template>
    <style scoped lang="sass">
    article
        padding: 16px
        height: 80vh
        overflow-y: scroll
        h1
            font-size: 16px
            font-weight: bold
            padding: 
                bottom: 20px
        h2
            font-size: 14px
            padding: 
                top: 18px
            color: #911054
        h3
            font-size: 14px
            padding: 
                top: 12px
        h2, h3
            font-weight: bold
            padding: 
                bottom: 12px
        p
            font-size: 14px
            line-height: 22px
            padding: 
                bottom: 10px
            span
                font-weight: bold
        [data-date_row]
            font-size: 12px
            padding: 
                top: 30px
        & > ol
            list-style: none
            li
                font-size: 14px
                line-height: 22px
                padding:
                    left: 12px
                    bottom: 10px
                text-indent: -17px
                &:nth-child(n+10)
                    padding-left: 12px
                    text-indent: -20px
                span
                    font-weight: bold
                ol
                    padding-top: 10px
                    li
                        padding:
                            left: 12px
                            bottom: 5px
                        text-indent: -16px
                [data-table_box]
                    width: 100%
                    margin-top: 15px
                    border-collapse: collapse
                    box-sizing: border-box
                    border: 1px solid #222
                    tr
                        border: 1px solid #222
                        th
                            width: 50%
                            height: 30px
                            text-align: center
                            line-height: 30px
                            font-weight: bold
                            background-color: #eeeeee
                            border: 1px solid #222
                        td
                            padding: 1rem
                            border: 1px solid #222
                            display: table-cell
                            vertical-align: middle
                [data-table_box="6"]
                    th
                        &:first-of-type
                            width: 35%
                        &:nth-of-type(2)
                            width: 45%
                    td
                        border: 0.1rem solid #222
                        &:last-of-type
                            text-align: center
    </style>